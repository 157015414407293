import $ from 'jquery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';


//run on load
//keep this clean and less jitter with sass and make used data attributes opacity 0
$( () => {
  //activate scrolltrigger
  gsap.registerPlugin( ScrollTrigger );

  //basic fade in
  if( $( ['data-fade'] ).length ) {
    let fadeItem = document.querySelectorAll( '[data-fade]' )
  
    fadeItem.forEach( ( item ) => {
      gsap.fromTo( 
        item, { 
          opacity: 0, 
          y: 50 
        }, {    
          opacity: 1,
          y: 0,
          duration: 0.27,

          scrollTrigger: {
            trigger: item,
            start: 'top bottom-=150',
            // markers: true,
          }
        }
      )
    } )
  }

  //basic waterfall
  if( $( ['data-waterfall'] ).length ) {
    let waterfallItems = document.querySelectorAll( '[data-waterfall]' );
  
    waterfallItems.forEach( ( item ) => {
      gsap.fromTo( 
        $( item ).children(), {
          opacity: 0,
          y: 25 
        }, {
          opacity: 1,
          y: 0,
          duration: 0.325,
          stagger: 0.125,

          scrollTrigger: {
            trigger: item,
            start: 'top bottom-=300',
            // markers: true,
          }
        }
      )
    })
  }
  
  gsap.fromTo( 
    $( '.swipe-left' ), {
      x: 0
    }, {
      x: '-75%',

      scrollTrigger: {
        trigger: $('#history'),
        start: 'top top+=100',
        // markers: true,
      }
    }
  )

  gsap.fromTo( 
    $( '.swipe-right' ), {
      x: 0
    }, {
      x: '75%',

      scrollTrigger: {
        trigger: $('#history'),
        start: 'top top+=100',
        // markers: true,
      }
    }
  )
})