import $ from 'jquery';
import LazyLoad from "vanilla-lazyload";

// Lazyload images
let myLazyLoad = new LazyLoad();

// mail handling
if( $( '[href^="mailto:"]' ).length ){
  $( '[href^="mailto:"]' ).each( function() {
    let $this = $( this )
    let match = $this.attr( 'href' ).match( '^mailto:(.*)\=$' )
  
    // Find mailto links that matches base64 encoded pattern and unobfuscate the address | view functions.php helpers
    if ( match && match.length >= 2 ) {
      let mailto = atob( match[1] )
  
      // Re-assign the address to the new value
      $this.attr( 'href', 'mailto:' + mailto )
  
      // If the link has no text put the email
      if ( ! $this.html() ) {
        $this.html( mailto )
      }
    }
  })
}