import $ from 'jquery';

$( window ).on( 'load', () => {
  $( '.nav-handle' ).on( 'click', () => {
    $( '.nav' ).toggleClass( 'open' )
    $( 'body' ).toggleClass( 'no-scroll' );
  } )

  $( '.nav-link' ).on( 'click', () => {
    if(  $( '.nav' ).hasClass('open') ){
      $( '.nav' ).removeClass( 'open' )
      $( 'body' ).removeClass( 'no-scroll' );
    }
  } )
});

// mailto spam handling | Pairs with base64 modification in functions.php > helpers
$( '[href^="mailto:"]' ).each( function() {
  let $this = $( this )
  let match = $this.attr( 'href' ).match( '^mailto:(.*)\=$' )

  // Find mailto links that matches base64 encoded pattern and unobfuscate the address
  if ( match && match.length >= 2 ) {
    let mailto = atob( match[1] )

    // Re-assign the address to the new value
    $this.attr( 'href', 'mailto:' + mailto )

    // If the link has no text put the email in
    if ( ! $this.html() ) {
      $this.html( mailto )
    }
  }
})